.btn {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  will-change: transform;
}

.btn:hover {
  position: relative;
  transform: scale(1.025);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25);
}

.btn:active {
  transform: scale(0.98);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  text-shadow: 0 -1px rgba(0, 0, 0, 0.1);
}

.btn:visited {
  transform: scale(1);
}
